<template>
  <div class="bottom-search">
    <h3>{{ textData.title }}</h3>
    <h4>
      {{ textData.content }}
    </h4>
    <div class="btn">
      <div @click="routerGo" class="left">
        <p>私募直通车</p>
        <i class="iconfont iconfenxiang"></i>
      </div>
      <div class="right">
        <p>基募评价</p>
        <i class="iconfont iconfenxiang"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import myaxios from "@/http/Myaxios";
import router from "@/router";
import { onMounted, ref } from "vue";
import upLoadOssApi from "@/utils/UpLoadApi";
import { ElMessage } from "element-plus";

const textData = ref({});
const getTextData = () => {
  let url = "/home/index/seven/list";
  myaxios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      textData.value = res.data.data[0];
    }
  });
};

// 跳转页面
const routerGo = () => {
  let res = upLoadOssApi.getUserInfo();

  if (res && res.accountType != "ins" && res.accountType != "person") {
    ElMessage.error("您的账户类型无法访问此页面");
  } else {
    router.push("/homes/Question");
  }
};

onMounted(() => {
  getTextData();
});
</script>

<style lang="scss" scoped>
.bottom-search {
  // width: 1200px;
  width: $screen-vw;
  margin: 0 auto;
  text-align: center;
  padding-top: $box-mt;
  padding-bottom: 120px;
  background-color: #e9f1fd;
  > h3 {
    font-weight: 400;
    font-size: $title-fz;
    color: $title1;
    margin-bottom: 40px;
  }
  > h4 {
    font-weight: 400;
    font-size: 20px;
    color: $title2;
    margin-bottom: 72px;
  }
  > .btn {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    > div {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 240px;
      height: 54px;
      border-radius: 27px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      transition: 0.3s;
      &:hover {
        @include btn-c;
      }

      > p {
        font-size: $font-size1;
        margin-right: 30px;
      }
      > i {
        font-size: 25px;
        font-weight: 100;
      }
    }
    > .left {
      background-color: $primary-red;
      margin-right: 66px;
    }
    > .right {
      background-color: $primary-blue;
      color: #fff;
    }
  }
}
</style>
