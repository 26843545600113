<template>
  <div class="foots">
    <div class="bk">
      <div class="container">
        <!-- 图标层 -->
        <div class="icon">
          <p>
            <img
              @mouseover="iconShow = 1"
              @mouseleave="iconShow = 0"
              :src="iconShow == 1 ? iconListWhite[0] : iconListBlack[0]"
              alt=""
            />
          </p>
          <p>
            <img
              @mouseover="iconShow = 2"
              @mouseleave="iconShow = 0"
              :src="iconShow == 2 ? iconListWhite[1] : iconListBlack[1]"
              alt=""
            />
          </p>
          <p>
            <img
              @mouseover="iconShow = 3"
              @mouseleave="iconShow = 0"
              :src="iconShow == 3 ? iconListWhite[2] : iconListBlack[2]"
              alt=""
            />
          </p>
          <p>
            <img
              @mouseover="iconShow = 4"
              @mouseleave="iconShow = 0"
              :src="iconShow == 4 ? iconListWhite[3] : iconListBlack[3]"
              alt=""
            />
          </p>
          <p>
            <img
              @mouseover="iconShow = 5"
              @mouseleave="iconShow = 0"
              :src="iconShow == 5 ? iconListWhite[4] : iconListBlack[4]"
              alt=""
            />
          </p>
        </div>
        <!-- 链接层 -->
        <div class="ahar">
          <p>跟随我们</p>
          <p @click="router.push('/bottom/privacy-policy')">隐私政策</p>
          <p @click="router.push('/bottom/cookies-page')">管理cookies</p>
          <p>知识产权</p>
          <p>广告</p>
          <p>荣誉</p>
          <p>
            <a href="https://beian.miit.gov.cn/">沪 ICP 备 2021021966 号</a>
          </p>
        </div>
        <!-- 文本声明 -->
        <div class="text">
          <p>Copyright©闲画云计算（上海）有限公司</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { ref } from "vue";

const iconShow = ref(0); // 0未激活 12345分别5按钮激活

const iconListWhite = [
  require("@/assets/foots/weChatI01.jpeg"),
  require("@/assets/foots/weBo01.jpeg"),
  require("@/assets/foots/Yutobe01.jpeg"),
  require("@/assets/foots/eMail01.jpeg"),
  require("@/assets/foots/phone01.jpeg"),
];
const iconListBlack = [
  require("@/assets/foots/weChatIs01.jpeg"),
  require("@/assets/foots/weBoS01.jpeg"),
  require("@/assets/foots/YutobeS01.jpeg"),
  require("@/assets/foots/eMailS01.jpeg"),
  require("@/assets/foots/phoneS01.jpeg"),
];
</script>

<style lang="scss" scoped>
.foots {
  > .bk {
    width: $screen-vw;
    background-color: #000000;
    margin: 0 auto;
    > .container {
      padding-top: 145px;
      padding-bottom: 91px;
      width: 1200px;
      margin: 0 auto;
      color: #f5f5f5;
      box-sizing: border-box;
      > .icon {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        > p {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          -webkit-justify-content: center;
          align-items: center;
          -webkit-align-items: center;
          transition: 0.3s;
          cursor: pointer;
          margin-right: 50px;
          > img {
            width: 36px;
            height: 36px;
          }
        }
      }
      > .ahar {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        margin-top: 50px;
        > p {
          cursor: pointer;
          margin-right: 45px;
          font-size: $font-size2;
          color: #d7d7d7;
          &:hover {
            text-decoration: underline;
          }
          > a {
            color: #d7d7d7;
            &:link {
              color: #d7d7d7;
            }
            &:visited {
              color: #d7d7d7;
            }
            &:hover {
              color: #d7d7d7;
            }
            &:active {
              color: #d7d7d7;
            }
          }
        }
      }
      > .text {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        margin-top: 50px;
        > p {
          margin-right: 20px;
          font-size: $font-size2;
          color: #d7d7d7;
        }
      }
    }
  }
}
</style>
