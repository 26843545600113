// 阿里oss服务器--上传文件
// 用户基本登录信息查询

import { ElMessage } from "element-plus";
import myaxios from "@/http/Myaxios";
import store from "@/store";

const upLoadOssApi = {
  /**
   * 文件上传
   * @param {object} res 后端服务返回的阿里上传文件签名
   * @param {object} uploadFile  encryption: true(保密)
   * @param {object} n  {n:userId(用户id),encryption: true(保密)}
   * @return 男：male；女：female；异常（身份证号码为空或长度、格式错误）：undefined
   */
  uploadFile1(res, uploadFile, n) {
    return new Promise((resolve, reject) => {
      if (res.code === 200) {
        let params = {};
        // 头像上传用
        if (n != undefined && n.userId) {
          params = {
            "Cache-Control": "no-cache",
            key: res.data.dir + "/" + n.userId + ".jpg", // 存储在 OSS 的文件路径
            OSSAccessKeyId: res.data.accessid, // accessKeyId
            policy: res.data.policy, // policy
            signature: res.data.signature, // 签名
            success_action_status: 200, // 成功后返回的操作码
            file: uploadFile.raw,
          };
        }
        // 普通命名+文件浏览加密用
        else if (n != undefined && n.encryption) {
          params = {
            key:
              res.data.dir + "/" + new Date().getTime() + "_" + uploadFile.name, // 存储在 OSS 的文件路径
            OSSAccessKeyId: res.data.accessid, // accessKeyId
            policy: res.data.policy, // policy
            signature: res.data.signature, // 签名
            success_action_status: 200, // 成功后返回的操作码
            "x-oss-object-acl": "private",
            file: uploadFile.raw,
          };
        }
        // 普通命名 + 基础用法
        else {
          params = {
            key:
              res.data.dir + "/" + new Date().getTime() + "_" + uploadFile.name, // 存储在 OSS 的文件路径
            OSSAccessKeyId: res.data.accessid, // accessKeyId
            policy: res.data.policy, // policy
            signature: res.data.signature, // 签名
            success_action_status: 200, // 成功后返回的操作码
            file: uploadFile.raw,
          };
        }

        // 文件全名（时间戳）
        let filePath =
          res.data.host +
          "/" +
          res.data.dir +
          "/" +
          new Date().getTime() +
          "_" +
          uploadFile.name;

        // 文件名前缀
        let filePathPrefix = process.env.VUE_APP_Ali_HOST + res.data.dir + "/";

        myaxios
          .upLoad(params)
          .then((res) => {
            if (res.status === 200) {
              ElMessage({
                message: "上传成功",
                type: "success",
              });

              resolve({ result: res.status, filePath, filePathPrefix }); // 返回成功时的结果
            } else {
              ElMessage({
                message: "上传失败" + res.statusText,
                type: "warning",
              });
              reject(res.status); // 返回失败时的结果
            }
          })
          .catch((error) => {
            reject("错误，请稍后再试"); // 发生错误时的结果
          });
      } else {
        ElMessage({
          message: "错误，请稍后再试",
          type: "warning",
        });
        reject("0"); // 返回错误时的结果
      }
    });
  },

  /**
   * 查询用户基本信息
   * @return {Object}
   */
  getInfo() {
    return new Promise((resolve, reject) => {
      let url = "/home/business/zUser/getInfo";
      myaxios
        .get(url)
        .then((res) => {
          // console.log(res);
          // 保存用户信息
          if (res.data.code == 200 && res.data.data) {
            // 更新用户信息
            store.commit("lodingSave", {
              token: res.data.data.token,
              usertInfo: res.data.data,
              userName: res.data.data.userName,
              headSculpture: res.data.data.headSculpture,
            });
            localStorage.setItem("usertInfo", JSON.stringify(res.data.data));
            localStorage.setItem(
              "userName",
              JSON.stringify(res.data.data.userName)
            );
            localStorage.setItem(
              "headSculpture",
              JSON.stringify(res.data.data.headSculpture)
            );

            resolve(res); // 返回成功时的结果
          } else {
            ElMessage({
              message: "请求失败",
              type: "warning",
            });
            reject(res.data.data); // 返回失败时的结果
          }
        })
        .catch((error) => {
          reject("错误，请稍后再试"); // 发生错误时的结果
        });
    });
  },

  /**
   * 查询本地存储的用户基本信息
   * @return {Object} userInfo
   */
  getUserInfo() {
    let userInfo =
      store.state.usertInfo ||
      JSON.parse(localStorage.getItem("usertInfo")) ||
      "";

    return userInfo;
  },

  /**
   * 更新用户持有电子卡状态
   * @return {Number} 0 无卡 1 个人卡 2 机构卡 3 办公室卡
   */
  getMyCards() {
    let url = "/home/business/market/cards/myCards";
    myaxios.get(url).then((res) => {
      // console.log(res);
      if (res.data.code == 200 && res.data.data) {
        if (res.data.data.office_card.haveCard == "1") {
          store.commit("haveCardsSave", 3);
          localStorage.setItem("haveCards", 3);
        } else if (res.data.data.org_card.haveCard == "1") {
          store.commit("haveCardsSave", 2);
          localStorage.setItem("haveCards", 2);
        } else if (res.data.data.person_card.haveCard == "1") {
          store.commit("haveCardsSave", 1);
          localStorage.setItem("haveCards", 1);
        } else {
          store.commit("haveCardsSave", 0);
          localStorage.setItem("haveCards", 0);
        }
      }
    });
  },
};

export default upLoadOssApi;
