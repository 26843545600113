import { ElMessage } from "element-plus";
import { onMounted, onUnmounted, ref } from "vue";

// directives.js
export const directives = {
  /**
   * v-click-outside
   * 点击元素外部时触发事件
   * <button v-clickOut="outside">按钮2</button>
   */
  clickOut: {
    mounted(el, binding) {
      function eventHandler(e) {
        if (el.contains(e.target)) {
          return false;
        }
        // 如果绑定的参数是函数，正常情况也应该是函数，执行
        if (binding.value && typeof binding.value === "function") {
          binding.value(e);
        }
      }
      // 用于销毁前注销事件监听
      el.__click_outside__ = eventHandler;
      // 添加事件监听
      document.addEventListener("click", eventHandler);
    },
    beforeUnmount(el) {
      // 移除事件监听
      document.removeEventListener("click", el.__click_outside__);
      // 删除无用属性
      delete el.__click_outside__;
    },
  },

  /**
   * v-copy
   * 实现一键复制文本内容，用于鼠标右键粘贴
   * <button v-copy="copyText">复制</button>
   */
  copy: {
    mounted(el, binding) {
      el.handler = () => {
        if (!binding.value) {
          ElMessage({
            message: "无复制内容",
            type: "warning",
          });
        } else {
          const input = document.createElement("input");
          input.value = binding.value;
          input.readOnly = "readonly";
          document.body.appendChild(input);
          // 选中并复制文本到剪切板
          input.select();
          document.execCommand("copy");
          // 移除input元素
          document.body.removeChild(input);
          ElMessage({
            message: "复制成功",
            type: "success",
          });
        }
      };

      // 指令绑定到元素时调用
      el.addEventListener("click", el.handler);
    },
    unmounted(el) {
      // 指令从元素解绑时调用
      el.removeEventListener("click", el.handler);
    },
  },

  /**
   * v-debounce
   * 节流 防止短时内重复提交表单
   * <button v-debounce="debounceClick()">防抖</button>
   */
  debounce: {
    mounted(el, binding) {
      let timer = null;
      el.handler = () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          binding.value();
        }, 1000);
      };

      el.addEventListener("click", el.handler);
    },
    unmounted(el) {
      // 指令从元素解绑时调用
      el.removeEventListener("click", el.handler);
    },
  },

  /**
   * v-draggable
   * 实现一个拖拽指令，可在页面可视区域任意拖拽元素
   * <div class="el-dialog" v-draggable></div>
   */
  draggable: {
    mounted(el, binding) {
      el.style.cursor = "move";

      el.onmousedown = function (e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          let x = e.pageX - disx;
          let y = e.pageY - disy;
          let maxX =
            document.body.clientWidth -
            parseInt(window.getComputedStyle(el).width);
          let maxY =
            document.body.clientHeight -
            parseInt(window.getComputedStyle(el).height);
          if (x < 0) {
            x = 0;
          } else if (x > maxX) {
            x = maxX;
          }

          if (y < 0) {
            y = 0;
          } else if (y > maxY) {
            y = maxY;
          }

          el.style.left = x + "px";
          el.style.top = y + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };

      el.addEventListener("click", el.handler);
    },
    unmounted(el) {
      // 指令从元素解绑时调用
      el.removeEventListener("click", el.handler);
    },
  },

  /**
 * v-permission
 * 实自定义一个权限指令，对需要权限判断的 Dom 进行显示隐藏
 * <!-- 显示 -->
  <button v-permission="'1'">权限按钮1</button>
  <!-- 不显示 -->
  <button v-permission="'10'">权限按钮2</button></div>
 */
  permission: {
    mounted(el, binding) {
      let permission = binding.value; // 获取到 v-permission的值
      if (permission) {
        let hasPermission = checkArray(permission);
        if (!hasPermission) {
          // 没有权限 移除Dom元素
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    },
  },

  /**
   * v-lazyLoad
   * 懒加载图片
   * <img v-lazyLoad="xxx.jpg" />
   */
  lazyLoad: {
    beforeMount(el, binding) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = binding.value;
            observer.unobserve(img);
          }
        });
      }, options);

      if (IntersectionObserver) {
        observer.observe(el);
      } else {
        lazyLoadFun.listenerScroll(el);
      }
    },
  },

  /**
 * v-waterMarker
 * 给整个页面添加背景水印
 * <!-- 显示 -->
   <div v-waterMarker="{text:'lzg版权所有',textColor:'rgba(180, 180, 180, 0.4)'}"></div>
 */
  waterMarker: {
    mounted(el, binding) {
      addWaterMarker(
        binding.value.text,
        el,
        binding.value.font,
        binding.value.textColor
      );
    },
  },

  /**
   * v-ellipsis
   * 超出部分省略号
   * <!-- 显示 -->
   <div v-ellipsis="100">这是一段超长的文本，需要被截断显示</div>
   */
  ellipsis: {
    mounted(el, binding) {
      const observer = new MutationObserver(() => {
        updateText();
      });

      let originalText = el.innerHTML;
      el.innerHTML = "";

      // 如果原始文本长度大于截取长度，截取并添加省略号

      if (originalText && originalText.length > binding.value) {
        el.textContent = originalText.slice(0, binding.value) + "...";
      } else {
        el.textContent = originalText; // 否则显示全部文本
      }

      observer.observe(el, { childList: true, subtree: true });
      onMounted(updateText);
      onUnmounted(() => observer.disconnect());

      function updateText() {
        const maxLength = binding.value;
        let text = el.textContent || el.innerText;
        if (text.length > maxLength) {
          text = text.substring(0, maxLength) + "...";
        }
        el.textContent = text;
      }
    },
  },
};

// 权限
const checkArray = (key) => {
  let arr = ["person", "ins"];
  let index = arr.indexOf(key);
  if (index > -1) {
    return true; // 有权限
  } else {
    return false; // 无权限
  }
};
// 检查权限
const checkUserPermission = () => {
  // 编写检查用户权限的逻辑，返回 true 表示有权限，false 表示无权限
  return false; // 示例：假设用户没有权限
};
// 懒加载
const lazyLoadFun = {
  // 监听scroll事件
  listenerScroll(el) {
    const handler = lazyLoadFun.throttle(lazyLoadFun.load, 300);
    lazyLoadFun.load(el);
    window.addEventListener("scroll", () => {
      handler(el);
    });
  },
  // 加载真实图片
  load(el) {
    const windowHeight = document.documentElement.clientHeight;
    const elTop = el.getBoundingClientRect().top;
    const elBtm = el.getBoundingClientRect().bottom;
    const realSrc = el.dataset.src;
    if (elTop - windowHeight < 0 && elBtm > 0) {
      if (realSrc) {
        el.src = realSrc;
        el.removeAttribute("data-src");
      }
    }
  },
  // 节流
  throttle(fn, delay) {
    let timer;
    let prevTime;
    return function (...args) {
      const currTime = Date.now();
      const context = this;
      if (!prevTime) prevTime = currTime;
      clearTimeout(timer);

      if (currTime - prevTime > delay) {
        prevTime = currTime;
        fn.apply(context, args);
        clearTimeout(timer);
        return;
      }

      timer = setTimeout(function () {
        prevTime = Date.now();
        timer = null;
        fn.apply(context, args);
      }, delay);
    };
  },
};
// 水印
const addWaterMarker = (str, parentNode, font, textColor) => {
  // 水印文字，父元素，字体，文字颜色
  var can = document.createElement("canvas");
  parentNode.appendChild(can);
  can.width = 200;
  can.height = 150;
  can.style.display = "none";
  var cans = can.getContext("2d");
  cans.rotate((-20 * Math.PI) / 180);
  cans.font = font || "16px Microsoft JhengHei";
  cans.fillStyle = textColor || "rgba(180, 180, 180, 0.3)";
  cans.textAlign = "left";
  cans.textBaseline = "Middle";
  cans.fillText(str, can.width / 10, can.height / 2);
  parentNode.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")";
};
