import router from "@/router";
import { ElMessage } from "element-plus";
import store from "@/store";

const permission = (to, from, next) => {
  let token = store.state.token || localStorage.getItem("Authorization");
  let usertInfo =
    store.state.usertInfo || JSON.parse(localStorage.getItem("usertInfo"));
  let haveCards =
    store.state.haveCards || localStorage.getItem("haveCards") || 0; // 0无卡 1个人卡  2机构卡 3办公室卡

  if (
    usertInfo &&
    usertInfo.accountType != "person" &&
    usertInfo.accountType != "ins"
  ) {
    usertInfo.accountType = "attach";
  }

  // 登录
  if (token) {
    // 个人账户|实名
    if (usertInfo.accountType == "person" && usertInfo.idCardAuth != "1") {
      ElMessage({
        message: "请先完成个人实名认证",
        type: "warning",
      });
      next("/userHome/step/real-name/person");
    }
    // 机构|实名
    // else if (usertInfo.accountType == "ins" && usertInfo.idCardAuth == "0") {
    //   ElMessage({
    //     message: "请先完成个人实名认证",
    //     type: "warning",
    //   });

    //   next("/userHome/step/real-name/person");
    // }
    else if (usertInfo.accountType == "ins" && usertInfo.idCardAuth != 2) {
      ElMessage({
        message: "请先完成企业实名认证",
        type: "warning",
      });

      next("/userHome/step/real-name/company");
    }
    // 机构|电子卡
    else if (
      to.meta &&
      to.meta.permission &&
      usertInfo.accountType == "ins" &&
      to.meta.permission.indexOf("ins_card") != -1
    ) {
      if (haveCards != 2 && haveCards != 3) {
        ElMessage({
          message: "请先购买365 · 机构电子卡",
          type: "warning",
        });

        next("/401");
      } else {
        next();
      }
    }
    // 附属账户+其他情况
    else {
      // 需要拦截的页面
      if (to.meta && to.meta.permission) {
        // 其他情况
        if (to.meta.permission.indexOf(usertInfo.accountType) != -1) {
          next();
        } else {
          next("/401");
          // next();
        }
      } else {
        next();
      }
    }
  }
  // 未登录
  else {
    if (
      to.path.indexOf("/login/") != -1 ||
      to.path == "/home/index" ||
      to.path.indexOf("/home/index/") != -1
    ) {
      next();
    } else {
      ElMessage({
        message: "请先登录",
        type: "warning",
      });
      next("/home/index");
    }
  }
};

export default permission;
