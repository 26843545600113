<template>
  <div class="Cookies">
    <!-- 标题层 -->
    <div class="title">
      <!-- <img src="@/assets/logo.svg" alt="" /> -->
      <img src="@/assets/logo/logoNew.svg" alt="" />
      <p>欢迎来到私募管家网</p>
      <i @click="alartClose" class="iconfont iconcuowu"></i>
    </div>

    <!-- 内容区域 -->
    <div class="content">
      <!-- 左侧导航 -->
      <div class="left-nav">
        <ul>
          <li
            v-for="(item, i) in privacy"
            :key="item.i"
            :style="
              privacyShow == i
                ? 'border-left: 5px solid #de0011; background-color: #FFFFFF;'
                : 'border-left: 5px solid transparent;'
            "
            @click="privacyShow = i"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>

      <!-- 右侧文本内容 -->
      <div class="right-text">
        <div class="right-title">
          <h3>{{ privacy[privacyShow].title }}</h3>
          <div>
            <el-switch
              v-show="privacyShow == 0"
              size="large"
              v-model="cookiesFrom.must"
              style="
                --el-switch-on-color: #de0011;
                --el-switch-off-color: #dcdfe6;
              "
              active-value="1"
              inactive-value="0"
            />
            <el-switch
              v-show="privacyShow == 1"
              size="large"
              v-model="cookiesFrom.analysis"
              style="
                --el-switch-on-color: #de0011;
                --el-switch-off-color: #dcdfe6;
              "
              active-value="1"
              inactive-value="0"
            />
            <el-switch
              v-show="privacyShow == 2"
              size="large"
              v-model="cookiesFrom.social"
              style="
                --el-switch-on-color: #de0011;
                --el-switch-off-color: #dcdfe6;
              "
              active-value="1"
              inactive-value="0"
            />
            <el-switch
              v-show="privacyShow == 3"
              size="large"
              v-model="cookiesFrom.advertise"
              style="
                --el-switch-on-color: #de0011;
                --el-switch-off-color: #dcdfe6;
              "
              active-value="1"
              inactive-value="0"
            />
            <el-switch
              v-show="privacyShow == 4"
              size="large"
              v-model="cookiesFrom.cookie"
              style="
                --el-switch-on-color: #de0011;
                --el-switch-off-color: #dcdfe6;
              "
              active-value="1"
              inactive-value="0"
            />
          </div>
        </div>
        <p>
          {{ privacy[privacyShow].content }}
        </p>
      </div>
    </div>

    <!-- 底部确认按钮 -->
    <div class="btn" @click="confirm"><p>确认我的选择</p></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

// 确认选择
const confirm = () => {
  alartClose();
};

// 权限选择
const cookiesFrom = ref({
  must: "1",
  analysis: "1",
  social: "1",
  advertise: "1",
  cookie: "1",
});

// 弹窗关闭
const emit = defineEmits(["alartClose", "submit"]);
const alartClose = () => {
  emit("alartClose", 1);
  localStorage.setItem("cookiesFrom", JSON.stringify(cookiesFrom.value));
};

onMounted(() => {
  if (localStorage.getItem("cookiesFrom")) {
    cookiesFrom.value = JSON.parse(localStorage.getItem("cookiesFrom"));
  }
});

const privacyShow = ref(0);
const privacy = [
  {
    title: "您的隐私",
    content:
      "当您访问任何网站时，它可能会在您的浏览器上存储或检索信息，主要是以cookie的形式。这些信息可能与您、您的偏好或您的设备有关，主要用于使网站按您预期工作。这些信息通常不会直接识别您的身份，但它可以为您提供更个性化的网络体验。因为我们尊重您的隐私权，您可以选择不允许某些类型的cookie。单击不同的类别标题以了解更多信息并更改我们的默认设置。然而，阻止某些类型的cookie可能会影响您对网站的体验和我们能够提供的服务。",
    a: "",
  },
  {
    title: "必要的Cookie",
    content:
      "这些cookie是网站运行所必需的，不能在我们的系统中关闭。它们通常仅针对您所做的相当于服务请求的行动而设置，例如设置您的隐私偏好、登录或填写表格。您可以将浏览器设置为阻止或提醒您这些cookie，但网站的某些部分将无法正常工作。这些cookie不会存储任何个人身份信息。",
    a: "",
  },
  {
    title: "目标Cookie",
    content:
      "这些cookie可能由我们的广告合作伙伴通过我们的网站设置。这些公司可能会使用它们来建立您兴趣的个人资料，并向您展示其他网站上的相关广告。它们不直接存储个人信息，而是基于唯一识别您的浏览器和互联网设备。如果您不允许这些cookie，您将体验到不那么有针对性的广告。",
    a: "",
  },
  {
    title: "性能 Cookie",
    content:
      "这些cookie允许我们计算访问量和流量来源，以便我们能够衡量和提高网站的性能。它们帮助我们了解哪些页面最受欢迎，哪些页面最不受欢迎，并了解访问者如何在网站上移动。这些cookie收集的所有信息都是汇总的，因此是匿名的。如果您不允许这些cookie，我们将不知道您何时访问了我们的网站，并且无法监控其性能。",
    a: "",
  },
  {
    title: "功能性 Cookie",
    content:
      "这些cookie使网站能够提供增强的功能和个性化。它们可能由我们或第三方提供商设置，我们已将其服务添加到我们的页面中。如果您不允许这些cookie，那么部分或全部服务可能无法正常运行。",
    a: "",
  },
];
</script>

<style lang="scss" scoped>
.Cookies {
  background-color: #f2f2f2;
  > .title {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 30px 20px;
    border-bottom: 1px solid #d8d8d8;
    > img {
      display: block;
      width: 45px;
    }
    > p {
      margin-left: 20px;
      flex: 1;
      -webkit-flex: 1;
    }
    > i {
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: #848484;
      }
    }
  }
  > .content {
    padding: 30px 20px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 150px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    display: -webkit-flex;
    > .left-nav {
      > ul {
        > li {
          width: 200px;
          padding: 20px;
          cursor: pointer;
          border-left: 5px solid transparent;
          border-bottom: 1px solid #d7d7d7;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    > .right-text {
      padding: 30px 20px;
      padding-top: 20px;
      font-size: 16px;
      > .right-title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        > h3 {
          margin-bottom: 20px;
          font-weight: 600;
        }
        > div {
          margin-top: -20px;
        }
      }

      > p {
        line-height: 25px;
      }
    }
  }
  > .btn {
    padding: 30px;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    > p {
      text-align: center;
      width: 180px;
      height: 45px;
      line-height: 45px;
      color: #f2f2f2;
      background-color: $primary-red;
      border-radius: 3px;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: $primary-red;
      }
    }
  }
}
</style>
