<!-- 前台主页 -->
<template>
  <div class="home-view">
    <div class="container">
      <el-affix :offset="0">
        <Header
          :isClickOutside="isClickOutside"
          @clickOutside="clickOutside"
          @loginPoint="loginPoint"
        ></Header>

        <div class="alart-box">
          <div v-show="alartShow" class="message">
            <div class="center-box">
              <div class="container">
                <div class="left">
                  <i class="iconfont iconzhifajianguan"></i>
                  <p>
                    监管规定：募集机构仅可以通过合法途径公开宣传私募基金管理人的品牌、发展战略、投资策略、管理团队、高管信息以及由中国基金业协会公示的已备案私募基金的基本信息。私募基金管理人应确保前述信息真实、准确、完整。
                  </p>
                </div>
              </div>
              <div @click="alartShow = false" class="right">
                <p>确认&nbsp;&nbsp;{{ countdownNum }}&nbsp;S</p>
              </div>
            </div>
          </div>
        </div>
      </el-affix>

      <!-- 二级路由占位符 -->
      <router-view />

      <!-- 重量脚 -->
      <Footer v-show="footerShow == 0"></Footer>
      <!-- 轻量脚 -->
      <Foots v-show="footerShow == 1" />

      <!-- cookie图标 -->
      <div @click="cookieShow = true" class="cook-logo">
        <img src="@/assets/cookie.png" alt="" />
      </div>

      <!-- cookie弹窗 -->
      <div v-show="cookieShow" class="cook-alart">
        <div class="center-box">
          <CookiesAlart @alartClose="alartClose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import Footer from "@/components/templateHF/Footer.vue";
import Header from "@/components/templateHF/Header.vue";
import Foots from "@/components/templateHF/Foot/Foots.vue";
import CookiesAlart from "@/components/CookiesAlart.vue";
import router from "@/router";

const loginPoint = () => {
  router.push("/login/login/index");
};

// cookie弹窗显示
const cookieShow = ref(false);
// cookie弹窗关闭
const alartClose = (e) => {
  if (e == 1) {
    cookieShow.value = false;
  }
};

// 横幅提示状态
const alartShow = ref(true);
onMounted(() => {
  countdown();

  // 横幅计时器
  setTimeout(function () {
    alartShow.value = false;
  }, 3000);
});

// 倒计时
const countdownNum = ref(3);
const countdown = () => {
  let clock = setInterval(doLoop, 1000);

  function doLoop() {
    if (countdownNum.value > 0) {
      countdownNum.value -= 1;
    } else {
      clearInterval(clock); //清除js定时器
      // getingCode.value = false;
      countdownNum.value = 3; //重置时间
    }
  }
};

// 点击搜索按钮外侧
const isClickOutside = ref(false);

const handler = (e) => {
  if (document.getElementsByClassName("bk")[0].contains(e.target)) {
    isClickOutside.value = false;
  } else {
    isClickOutside.value = true;
  }
};
const clickOutside = () => {
  isClickOutside.value = false;
};
onMounted(() => {
  document.addEventListener("click", handler);

  // 邀请来的用户弹出登录框
  if (router.currentRoute.value.params.inviterCode) {
    router.push("/login/login/index");
  }
});
onUnmounted(() => {
  document.removeEventListener("click", handler);
});

// 底部导航栏显示切换
const footerShow = ref(0); // 0重量脚 1轻量脚

// 监听当前路由
watch(
  () => router.currentRoute.value.path,
  (newValue) => {
    if (newValue == "/home/index") {
      footerShow.value = 0;
    } else {
      footerShow.value = 1;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.home-view {
  background-color: #f6f6fa;
  > .container {
    width: $screen-vw;
    margin: 0 auto;
    background-color: $white;
    .alart-box {
      position: relative;
      > .message {
        position: absolute;
        width: 100%;
        z-index: 980;
        box-sizing: border-box;
        top: 0;
        left: 0;
        > .center-box {
          box-sizing: border-box;
          margin: 0 auto;
          width: $screen-vw;
          background-color: #1f232f;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          padding: 5px 10px;
          padding-left: 53px;
          padding-right: 20px;
          position: absolute;
          height: 52px;
          > .container {
            width: $screen-vw;
            margin: 0 auto;
            padding: 10px 0;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            align-items: center;
            -webkit-align-items: center;

            > .left {
              width: 100%;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              padding-left: 12px;

              > i {
                display: block;
                font-size: 24px;
                margin-right: 14px;
                color: $primary-red;
              }
              > p {
                color: #fff;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 2px;
              }
            }
          }
          > .right {
            cursor: pointer;
            display: flex;
            display: -webkit-flex;
            justify-content: end;
            -webkit-justify-content: end;
            > p {
              width: 100px;
              height: 30px;
              box-sizing: border-box;
              border-radius: 100px;
              background-color: $primary-blue;
              color: #fff;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              justify-content: center;
              -webkit-justify-content: center;
            }
            > i {
              display: block;
              font-size: 22px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .cook-logo {
    position: fixed;
    bottom: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: $box-shadow;
    cursor: pointer;
    z-index: 9999;
    > img {
      width: 66px;
      height: 66px;
      display: block;
    }
  }
  .cook-alart {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(43, 43, 43, 0.7215686275);
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    > .center-box {
      width: 800px;
      // height: 400px;
      background-color: #fff;
    }
  }
}

@media (max-width: 600px) {
}
</style>
